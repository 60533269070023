<template>
  <div
    class="d-flex align-items-center justify-content-center login-background"
  >
    <b-overlay :show="loading" rounded="sm">
      <b-card border-variant="light" class="login-card">
        <div class="d-flex justify-content-between mb-3">
          <b-card-title class="mb-0"> Linking Accounts</b-card-title>
          <img :src="logo" alt="Qognite Logo" class="logo" />
        </div>

        <div v-if="accountsLinked">
          <b-card-text>
            Your TQ42 Account has been linked to your Qognite account. You can
            now log into your Qognite Account using the 'Sign in with TQ42'
            button.
          </b-card-text>

          <b-button
            :disabled="loading"
            :href="auth0LoginUrl"
            class="w-100 login-tq42"
            variant="outline-dark"
          >
            <div class="d-flex align-items-center justify-content-center my-1">
              <div class="login-tq42-text mr-1">Sign in with</div>
              <div class="login">
                <img :src="tq42_logo" alt="TQ42 Logo" class="tq42_logo" />
              </div>
            </div>
          </b-button>
        </div>

        <div
          v-else
          class="d-flex align-content-center justify-content-center py-4"
        >
          <div>
            <b-spinner class="mr-2" label="Small Spinner" small />
          </div>
          <div>
            <p class="mb-0">Linking Accounts in Progress</p>
          </div>
        </div>
      </b-card>
    </b-overlay>
    <Footer />
  </div>
</template>

<script>
import qognite_logo from "@/assets/images/qognite-logo.svg";
import tq42_logo from "@/assets/images/tq42_logo.svg";
import axios from "axios";
import cfg from "@/config";
import Footer from "@/components/Footer.vue";

export default {
  name: "LinkAccounts",
  components: { Footer },
  data() {
    return {
      email: "",
      accountsLinked: true,
      logo: qognite_logo,
      tq42_logo: tq42_logo,
      loading: false,
      auth0LoginUrl: process.env.VUE_APP_AUTH0_LOGIN_URL,
    };
  },
  async mounted() {
    const userId = this.$route.query.user_id;
    const tq42Id = this.$route.query.tq42_id;
    const code = this.$route.query.code;

    try {
      await axios.put(
        cfg.BASE_URL + cfg.BASE_IDENTITY_VERSION_URL + "/link-accounts",
        {
          userId: userId,
          tq42Id: tq42Id,
          verificationCode: code,
        }
      );
      await this.$router.replace("/link-accounts");
      this.accountsLinked = true;
    } catch (err) {
      console.log(err);
    }
  },
};
</script>
